import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import {
  Grid, Box, useTheme, useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  '&.RentalsAndToursPackagesSectionSkelton': {
    display: 'flex',
    '& .btn': {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
        order: 1,
      },
    },
    '& .title': {
      [theme.breakpoints.down('lg')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    '& .cardContiners': {
      margin: theme.spacing(2, 0),
      '& .cardItem': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .cardDetails': {
          width: '100%',
          '& .MuiSkeleton-root': {
            margin: 'auto',
            backgroundColor: 'rgb(247 247 247)',
            visibility: 'visible',
            marginBottom: theme.spacing(1),
          },
        },
      },
    },
  },
}));
const RentalsAndToursPackagesSectionSkelton = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const getnumber = () => {
    let n;
    if (isTablet && !isMobile) {
      n = 2;
    } else if (isTablet && isMobile) {
      n = 1;
    } else {
      n = 3;
    }
    return n;
  };
  return (
    <StyledBox className="RentalsAndToursPackagesSectionSkelton">
      <Grid container rowSpacing={0} columnSpacing={{ xs: 3 }}>
        <Grid item xs={12} lg={6} className="title">
          <Skeleton variant="rectangular" width="40%" height={56} />
        </Grid>
        <Grid item xs={12} lg={6} className="btn">
          <Skeleton variant="rectangular" width="40%" height={56} />
        </Grid>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{ xs: 3 }}
          className="cardContiners">
          {[...Array(getnumber())].map(() => (
            <Grid item lg={4} md={6} xs={12}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height={240}
                className="cardItem">
                <Box className="cardDetails">
                  <Skeleton variant="rectangular" width="15%" height={50} />
                  <Skeleton variant="rectangular" width="60%" height={40} />
                  <Skeleton variant="rectangular" width="40%" height={30} />
                </Box>
              </Skeleton>
            </Grid>
          ))}
          ;
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export { RentalsAndToursPackagesSectionSkelton };
