import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'next-i18next';

import PATHS from 'lib/navigation/paths';

import Bike from 'views/common/components/UI/Icons/Bike';
import Map from 'views/common/components/UI/Icons/Map';
import SelfGuidedTours from 'views/common/components/UI/Icons/SelfGuidedTours';
import { TourPackage } from 'views/common/components/UI/TourPackage';
import CustomTripDialog from 'views/common/components/Logical/Layout/MenuBar/CustomTripDialog';
import { SliderSectionContiner } from 'views/common/components';

const RentalsAndToursPackagesSection = () => {
  const { t } = useTranslation(
    'fe_er_common_renrals_and_tours_packages_section',
  );
  const [openCustomTripsDialog, setOpenCustomTripsDialog] = useState(false);
  const handleCustomTripsClick = (e) => {
    e.preventDefault();
    setOpenCustomTripsDialog(true);
  };
  const handleCustomTripsDialogClose = () => {
    setOpenCustomTripsDialog(false);
  };

  const buttons = [
    {
      text: t(
        'fe_er_common_renrals_and_tours_packages_section:custom_trips_groups',
      ),
      onClick: handleCustomTripsClick,
    },
  ];
  const tourPackageItems = [
    {
      id: 1,
      title: t(
        'fe_er_common_renrals_and_tours_packages_section:motorcycle_rental',
      ),
      BGImage: '/imgs/tourPackageBox1.jpeg',
      icon: <Bike />,
      path: PATHS.motorcycleRental(),
    },
    {
      id: 2,
      title: t(
        'fe_er_common_renrals_and_tours_packages_section:self_guided_tours',
      ),
      subTitle: t(
        'fe_er_common_renrals_and_tours_packages_section:bike_hotel_route',
      ),
      BGImage: '/imgs/tourPackageBox2.jpeg',
      icon: <Map />,
      path: PATHS.tour.selfTourIndex(),
    },
    {
      id: 3,
      title: t(
        'fe_er_common_renrals_and_tours_packages_section:guided_motorcycle_tours',
      ),
      BGImage: '/imgs/tourPackageBox3.jpeg',
      icon: <SelfGuidedTours />,
      path: PATHS.tour.guidedTourIndex(),
    },
  ];

  return (
    <>
      <SliderSectionContiner
        title={t(
          'fe_er_common_renrals_and_tours_packages_section:motorcycle_rental_tour_packages',
        )}
        component="h2"
        buttons={buttons}
        deskTopItems={3}
        carouselArrow={false}>
        {tourPackageItems.map((item) => (
          <Box className="CarouselItem" key={item.id}>
            <TourPackage
              title={item.title}
              subTitle={item.subTitle}
              BGImage={item.BGImage}
              icon={item.icon}
              path={item.path}
            />
          </Box>
        ))}
      </SliderSectionContiner>
      <CustomTripDialog
        open={openCustomTripsDialog}
        onClose={handleCustomTripsDialogClose}
      />
    </>
  );
};
export { RentalsAndToursPackagesSection };
