import React, { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import StyledBox, {
  StyledMainWidgetWrapper,
} from 'views/common/components/UI/MainWidgetContainerSection/MainWidgetContainerSection.styles';
import { useSelector } from 'react-redux';
import Image from 'next/image';
import Typography500 from 'views/common/components/UI/Typography500';

const MainWidgetContainerSection = (props) => {
  const {
    className,
    locationTitle,
    WidgetBackgroundImage = {},
    announcementBar = false,
    smallHeroImg,
    ...restOfProps
  } = props;
  const { isCrawler, deviceType } = useSelector((state) => state.globalData);
  const [renderAllWidget, setRenderAllWidget] = useState(false);
  const widgetBGImage = useMemo(() => {
    if (deviceType === 'mobile' || isCrawler) {
      return WidgetBackgroundImage.small_url;
    }
    if (deviceType === 'tablet') {
      return WidgetBackgroundImage.medium_url;
    }
    return WidgetBackgroundImage.large_url;
  }, [WidgetBackgroundImage]);

  const handleRenderAllWidget = () => setRenderAllWidget(true);
  return (
    <StyledBox
      smallHeroImg={smallHeroImg}
      className={`MWCSContainer ${className}`}
      renderAllWidget={renderAllWidget}>
      <span className="MWCSBlackOverlay" />
      <Box className="MWCSWidetWrapperContainer">
        {locationTitle && (
          <Typography500
            className={`MWCSTitle ${renderAllWidget && 'MWCSDarkTitle'}`}
            variant="h2"
            component="h1">
            {locationTitle}
          </Typography500>
        )}

        <StyledMainWidgetWrapper
          vertical={false}
          renderAllWidget={renderAllWidget}
          handleRenderAllWidget={handleRenderAllWidget}
          handleRender
          announcementBar={announcementBar}
          {...restOfProps}
        />
      </Box>
      <Box className="MWCSImgContainer">
        <Image
          className="MWCSImg"
          src={widgetBGImage}
          data-src={widgetBGImage}
          data-srcset={`${WidgetBackgroundImage.small_url} 320w, ${WidgetBackgroundImage.medium_url} 600w, ${WidgetBackgroundImage.large_url} 1024yw`}
          layout="fill"
          unoptimized
          priority
        />
      </Box>
    </StyledBox>
  );
};

export { MainWidgetContainerSection };
