import React from 'react';
import { Grid } from '@mui/material';
import ExpandableDescription from 'views/common/components/UI/ExpandableDescription';
import { styled } from '@mui/material/styles';
import Typography500 from 'views/common/components/UI/Typography500';
import { ImgBox } from 'views/common/components';
import Youtube from 'react-lazyload-youtube';
import 'react-lazyload-youtube/dist/index.css';

const StyledGrid = styled(Grid)(() => ({
  '& .paragraphGrid': {
    maxWidth: '100%',
    flexGrow: 1,
  },
  '& .objIframe': {
    maxWidth: '100%',
    '& img': {
      height: '100%',
      objectFit: 'cover',
    },
  },
}));

const ParagraphWithEmbeddedObjects = (props) => {
  const {
    object,
    isImage,
    isYoutube = false,
    youtubeId,
    title,
    desc,
    collapsedSize = 50,
    className,
    titleComponet = 'p',
  } = props;
  // missing skeletone variant

  const renderRightSection = () => {
    if (isImage) {
      return <ImgBox image={object} imgHeight="315px" />;
    }
    if (isYoutube) {
      return <Youtube videoId={youtubeId} width="100%" height="312px" />;
    }
    return (
      <iframe
        className="objIframe"
        title="EagleRider Motorcycle Rentals & Tours"
        width="100%"
        height="312"
        src={object}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    );
  };
  return (
    <StyledGrid container className={`${className} root`} spacing={2}>
      <Grid item xs={12} md={12} lg={6} className="paragraphGrid">
        <Typography500
          variant="h3"
          component={titleComponet}
          className="PWEOTitle">
          {title}
        </Typography500>
        <ExpandableDescription
          description={desc}
          collapsedSize={collapsedSize}
        />
      </Grid>
      {(object || youtubeId) && (
        <Grid item xs={12} md={12} lg={6} className="imgGrid">
          {renderRightSection()}
        </Grid>
      )}
    </StyledGrid>
  );
};
export { ParagraphWithEmbeddedObjects };
