import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

const StyledBox = styled(Box)(({ theme }) => ({
  '&.DC-Root': {
    position: 'relative',
  },
  '& .DC-FooterBox': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    bottom: '0',
    boxSizing: 'border-box',
    '& .MuiSkeleton-root': {
      backgroundColor: theme.palette.grey.A000,
    },
  },
}));

const DestinationCardSkeleton = () => (
  <StyledBox className="DC-Root">
    <Skeleton variant="rectangular" width="100%" height={230} />
    <Box className="DC-FooterBox">
      <Skeleton variant="text" width="30%" height={40} />
      <Skeleton variant="text" width="30%" height={40} />
    </Box>
  </StyledBox>
);
export { DestinationCardSkeleton };
