/* eslint-disable no-use-before-define */
import React from 'react';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'next-i18next';

import { countries } from 'lib/data/countries';
import getCountryFlag from 'lib/utils/getCountryFlag';

const CountriesAutocomplete = ({
  codes = false,
  errorText,
  value,
  onChange,
  disabled,
  showPhone = false,
  error,
  label,
  variant = 'outlined',
  className,
}) => {
  const { t } = useTranslation('fe_er_common_countries_auto_complete');

  return (
    <Autocomplete
      id="country-select"
      name="country"
      className={className}
      style={{ width: '100%' }}
      options={countries}
      disabled={disabled}
      value={
        value
          ? countries[countries.map((c) => c.code).indexOf(value.toUpperCase())]
          : null
      }
      onChange={(event, newValue) => {
        newValue !== null && onChange(event, newValue.code, newValue.phone);
      }}
      onInputChange={() => {}}
      autoHighlight
      disableClearable
      getOptionLabel={(option) => option.label || ''}
      renderOption={(props, option) => (
        <Box
          {...props}
          sx={{
            fontSize: 15,
            '& > span': {
              marginRight: 10,
              fontSize: 18,
            },
          }}>
          <span>
            <span
              style={{
                marginRight: '8px',
                width: '32',
                height: '16px',
              }}>
              {getCountryFlag(option.code, true)}
            </span>
            {option.label}
            {' '}
            {showPhone && ` (+${option.phone})`}
          </span>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          required
          label={
            label
            || (codes
              ? t('fe_er_common_countries_auto_complete:country_or_region')
              : t('fe_er_common_countries_auto_complete:country_of_residence'))
          }
          variant={variant}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          error={Boolean(error)}
          helperText={errorText}
        />
      )}
    />
  );
};

export { CountriesAutocomplete };
export default CountriesAutocomplete;

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
