import React from 'react';
import { Box, Typography } from '@mui/material';
import Typography500 from 'views/common/components/UI/Typography500';
import { StyledBox } from 'views/common/components/UI/TourPackage/TourPackage.styles';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import { ImgBox } from 'views/common/components';

const TourPackage = (props) => {
  const {
    title, BGImage, icon, subTitle, path,
  } = props;
  return (
    <NavigatorLink href={path}>
      <StyledBox className="TourPackage" BGImage={BGImage}>
        <ImgBox
          imgHeight="100%"
          image={{
            src: BGImage,
            alt: 'Motorcycle Rental & Tour Packages',
          }}
        />
        <Box className="textContainer">
          {icon}
          <Typography500 variant="h3" component="p" className="TPTitle">
            {title}
          </Typography500>
          <Typography variant="body2" component="p">
            {subTitle}
          </Typography>
        </Box>
        <Box className="overlay" />
      </StyledBox>
    </NavigatorLink>
  );
};
export { TourPackage };
