import React from 'react';
import { Box } from '@mui/material';
import { ImgBox } from 'views/common/components';
import Typography500 from 'views/common/components/UI/Typography500';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StyledDestinationCardLink from 'views/common/components/UI/DestinationCard/DestinationCard.styles';

const DestinationCard = (props) => {
  const {
    title, image, link, titleComponent = 'p',
  } = props;

  return (
    <StyledDestinationCardLink className="DCRoot" href={link}>
      <ImgBox image={image} imgHeight="100%" priority={image.priority} />
      <Box className="DCFooterBox">
        <Typography500
          variant="h5"
          component={titleComponent}
          className="DCFooterTitle">
          {title}
        </Typography500>
        <Box display="flex" alignItems="center" className="DCFooterIcon">
          <ChevronRightIcon />
        </Box>
      </Box>
      <Box className="DCHoverLayer" />
    </StyledDestinationCardLink>
  );
};
export { DestinationCard };
