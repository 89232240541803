import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

const StyledGrid = styled(Grid)(({ theme }) => ({
  '& .imgGrid': {
    [theme.breakpoints.down('md')]: {
      maxHeight: theme.spacing(39.375),
    },
  },
}));

const ParagraphWithEmbeddedObjectsSkeleton = (props) => {
  const { media } = props;
  return (
    <StyledGrid container className="root" spacing={2}>
      <Grid item xs={12} md={media ? 6 : 12} className="paragraphGrid">
        <Skeleton variant="text" height={40} width="60%" />
        <Skeleton variant="text" height={40} width="80%" />
      </Grid>
      {media && (
        <Grid item xs={12} md={6} className="imgGrid">
          <Skeleton variant="rectangular" width="100%" height={290} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Skeleton variant="text" width="20%" height={40} />
      </Grid>
    </StyledGrid>
  );
};
export { ParagraphWithEmbeddedObjectsSkeleton };
