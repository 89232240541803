import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { MainWidgetWrapper } from 'views/common/components/Logical/MainWidgetWrapper';

const StyledBox = styled(Box)(({ theme, renderAllWidget, smallHeroImg }) => ({
  height: 'auto',
  position: 'relative',
  minHeight: smallHeroImg ? theme.spacing(55) : theme.spacing(86.25),
  padding: theme.spacing(6, 0),
  [theme.breakpoints.down('xl')]: {
    minHeight: smallHeroImg ? theme.spacing(55) : theme.spacing(75),
  },
  [theme.breakpoints.down('md')]: {
    minHeight: theme.spacing(30),
    padding: 0,
    paddingBottom: renderAllWidget && theme.spacing(2),
    boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.15)',
  },
  '& .MWCSBlackOverlay': {
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.palette.grey[900],
      opacity: '.2',
    },
  },
  '& .MWCSImgContainer': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: '-1',
    [theme.breakpoints.down('md')]: {
      // position: renderAllWidget && 'relative',
      // height: renderAllWidget && theme.spacing(17),
      display: renderAllWidget && 'none',
    },
  },
  '& .MWCSImg': {
    objectFit: 'cover',
    objectPosition: smallHeroImg ? 'center' : 'right 85%',
    [theme.breakpoints.down('md')]: {
      objectPosition: 'center',
    },
  },
  '& .MWCSWidetWrapperContainer': {
    maxWidth: theme.spacing(98),
    margin: 'auto',
    paddingTop: smallHeroImg ? theme.spacing(7) : theme.typography.pxToRem(76),
    [theme.breakpoints.down('lg')]: {
      maxWidth: theme.spacing(88),
    },
    [theme.breakpoints.down('md')]: {
      position: 'initial',
      width: 'auto',
      transform: 'unset',
      backgroundColor: renderAllWidget
        ? theme.palette.common.white
        : 'transparent',
      padding: theme.spacing(2.5, 2.5, 1, 2.5),
    },
  },
  '& .MWCSTitle': {
    color: theme.palette.common.white,
    textShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
    position: 'relative',
    zIndex: 0,

    [theme.breakpoints.down('lg')]: {
      fontSize: theme.spacing(3.5),
      lineHeight: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(2.5),
      lineHeight: theme.spacing(3.25),
    },
    '&.MWCSDarkTitle': {
      [theme.breakpoints.down('md')]: {
        color: theme.palette.secondary.main,
        textShadow: 'unset',
      },
    },
  },
}));

export const StyledMainWidgetWrapper = styled(MainWidgetWrapper)(
  ({ theme, renderAllWidget }) => ({
    '& .WTContainer': {
      width: '100%',
    },
    '& .WTButton': {
      [theme.breakpoints.down('md')]: {
        color: `${
          renderAllWidget ? theme.palette.grey[900] : theme.palette.common.white
        } !important`,
      },
      display: 'flex',
      justifyContent: 'space-between',
      '& .WTTitle': {
        fontSize: theme.typography.pxToRem(32),
        [theme.breakpoints.down('md')]: {
          fontSize: theme.typography.pxToRem(16),
        },
      },
      '& .WTMoreText': {
        fontWeight: '600',
      },
    },
  }),
);
export default StyledBox;
